/* User Action Types */
export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'

/* Channel Action Types */
export const SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL"
export const SET_PRIVATE_CHANNEL = "SET_PRIVATE_CHANNEL"
export const SET_USER_POSTS = "SET_USER_POSTS"

/* Colors Action Types */
export const SET_COLORS = "SET_COLORS"
